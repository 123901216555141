import React from 'react'
import { Link } from 'gatsby'

class NotFound extends React.Component {
  render() {
    return (
      <div className="not-found">
        <p>
          Page not found <br />
          <Link className="not-found__back" to="/">
            Back to homepage
          </Link>
        </p>
      </div>
    )
  }
}

export default NotFound
